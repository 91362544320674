<template>
  <div class="GlobalTable">
    <div class="button_top">
      <div class="faultBtn">
        <div
          :class="contractState === '00' ? 'active' : 'none'"
          @click="btnClick('00')"
        >
          未生效({{ applyNumber.unStartNum || 0 }})
        </div>
        <div
          :class="contractState === '01' ? 'active' : 'none'"
          @click="btnClick('01')"
        >
          已生效({{ applyNumber.startNum || 0 }})
        </div>
        <div
          :class="contractState === '10' ? 'active' : 'none'"
          @click="btnClick('10')"
        >
          已过期({{ applyNumber.overdueNum || 0 }})
        </div>
        <div
          :class="contractState === '20' ? 'active' : 'none'"
          @click="btnClick('20')"
        >
          已作废({{ applyNumber.cancelledNum || 0 }})
        </div>
      </div>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="addCertificate('add')"
        >添加合同</el-button
      >
    </div>
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="合同类型" slot="contractType" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.contractType == "00" ? "回收资金授信" : "" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="合同凭证"
        width="130px"
        slot="contractDocument"
        align="center"
      >
        <template slot-scope="{ row }">
          <div
            class="images-box"
            v-if="row.voucherList && row.voucherList.length > 0"
          >
            <img :src="row.voucherList[0]" alt="" />
            <p @click="openImgBox(row)" class="text-down"
              >共{{ row.voucherList.length }}张</p
            >
          </div>
          <div v-else>暂无图片</div>
        </template>
      </el-table-column>
      <el-table-column label="商户类型" slot="merchantType" align="center">
        <template slot-scope="{ row }">
          <div class="Remarks">
            <span>{{ row.enterpriseType == "00" ? "回收商" : "门店商" }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="合同有效期"
        width="130px"
        slot="startTime"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span>{{ row.startTime }}到{{ row.endTime }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="添加人"
        width="130px"
        slot="addPeople"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span>{{ row.adminName }}-{{ row.adminAcc }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="作废操作人"
        width="130px"
        v-if="contractState === '20'"
        slot="cancellationPeople"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span
              >{{ row.cancelledAdminName }}-{{ row.cancelledAdminAcc }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="作废时间"
        width="130px"
        v-if="contractState === '20'"
        slot="cancellationTime"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span>{{ row.cancelledTime || "--" }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="作废原因"
        width="130px"
        v-if="contractState === '20'"
        slot="cancellationCause"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span>{{ row.cancelledNote || "--" }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        v-if="contractState !== '20'"
        slot="operation"
        align="center"
        fixed="right"
        width="130px"
      >
        <template slot-scope="{ row }">
          <span v-if="contractState !== '10'">
            <el-button size="mini" type="danger" round @click="handleBtn(row)"
              >作废</el-button
            >
          </span>
          <span v-if="contractState == '10'">
            <el-button
              size="mini"
              type="success"
              round
              @click="addCertificate('edti', row)"
              >续签合同</el-button
            >
          </span>
        </template>
      </el-table-column>
    </GlobalTable>
    <el-drawer
      title="+添加合同"
      :visible.sync="drawer"
      direction="rtl"
      size="35%"
      :show-close="false"
      :wrapperClosable="false"
      :before-close="siginClose"
    >
      <div class="center_book">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <div>
            <p style="margin-bottom: 15px">合同基本信息</p>
            <el-form-item label="合同名称" prop="contractName">
              <el-input
                v-model="ruleForm.contractName"
                maxlength="50"
                placeholder="请输入合同名称，最多50字"
              ></el-input>
            </el-form-item>
            <el-form-item label="合同类型" prop="contractType">
              <el-select
                style="width: 100%"
                :popper-append-to-body="false"
                v-model="ruleForm.contractType"
                placeholder="请选择合同类型"
                clearable
                filterable
              >
                <el-option
                  v-for="item in productList"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <p style="margin-bottom: 15px">签署单位信息</p>
            <el-form-item label="选择回收商" prop="merchantId">
              <el-select
                style="width: 100%"
                filterable
                v-model="ruleForm.merchantId"
                placeholder="请选择回收商名称"
                @change="merchantSlect"
                clearable
              >
                <el-option
                  v-for="item in machineType"
                  :key="item.merchantId"
                  :label="item.merchantName"
                  :value="item.merchantId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="代表签署人" prop="merchantId">
              <el-select
                style="width: 100%"
                :disabled="!ruleForm.merchantId"
                filterable
                v-model="ruleForm.merchantStaffId"
                @change="merchantStaffSelect"
                placeholder="选择签署人姓名"
                clearable
              >
                <el-option
                  v-for="item in shanpsSelectList"
                  :key="item.staffId"
                  :label="item.staffName"
                  :value="item.staffId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系电话" prop="signatoryPhone">
              <el-input
                clearable
                v-model="ruleForm.signatoryPhone"
                maxlength="50"
                placeholder="请输入签署人联系电话"
              ></el-input>
            </el-form-item>
          </div>
          <div>
            <p style="margin-bottom: 15px">合同签署信息</p>
            <el-form-item
              label="合同签署凭证"
              prop="voucherList"
              label-width="110px"
            >
              <ElImgUploadGroup
                place-txt="请上传本次的签署凭证（限9张，大小不超过2M，格式为JPG或PNG）"
                :length="9"
                :exist-img-list="ruleForm.voucherList"
                @handleChange="handleUploadGroupChange($event)"
              />
            </el-form-item>
            <el-form-item label="合同有效期" required label-width="110px">
              <el-form-item prop="lifespanTime">
                <el-date-picker
                  value-format="timestamp"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  v-model="ruleForm.lifespanTime"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button size="small" @click="resetForm('ruleForm')"
              >取消</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="submitForm('ruleForm')"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <!-- 合同作废弹框 -->
    <el-dialog
      class="acc_dialog"
      title="作废提示"
      :visible.sync="cancelReceiveShow"
      :close-on-click-modal="false"
      width="540px"
      @closed="cancelReceiveClose"
    >
      <p class="lv_fc_red" style="margin-bottom: 20px">
        说明：合同作废后不会自动关闭授信，需业务人员自行判断是否关闭授信业务
      </p>
      <div
        style="
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 20px;
        "
      >
        是否确认作废该合同？
      </div>
      <div class="command" style="margin-top: 30px">
        <div style="width: 100px">
          <span style="color: red">*</span>作废原因：
        </div>
        <el-input
          maxlength="50"
          type="textarea"
          :rows="4"
          placeholder="请输入原因，最多50字"
          v-model="cancelResonTxt"
        >
        </el-input>
      </div>
      <div class="command" style="margin-top: 30px">
        <div style="width: 100px">
          <span style="color: red">*</span> 动态口令：
        </div>
        <el-input
          v-model="cancelCommandTex"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="cancelReceiveClose">取消</el-button>
        <el-button type="primary" @click.native="cancelReceiveSure"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!-- 商品列表 -->
    <el-dialog
      :title="goodsListTitle"
      :visible.sync="goodsVisible"
      :close-on-click-modal="false"
      width="1000px"
      @close="btnClose"
    >
      <div class="flex staff-list-form" style="margin-bottom: 20px">
        <div class="flex" style="margin-right: 10px">
          <!-- <span style="width: 56px">商品名称</span>
            <el-input
              v-model="productName"
              size="small"
              clearable
              placeholder="请输入商品名称"
            ></el-input>
          </div> -->
          <span style="width: 60px">商品名称</span>
          <el-select
            v-model="productId"
            filterable
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in productList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-right: 10px">
          <span style="width: 93px">所属分类</span>
          <el-select
            v-model="goodsClass"
            filterable
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in goodClassList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span style="width: 93px">商品状态</span>
          <el-select
            v-model="goodsState"
            filterable
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in goodsStateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          style="margin-left: 10px"
          size="small"
          @click="searchGoods"
        >
          查询
        </el-button>
      </div>
      <GlobalTable
        ref="GlobalTable"
        :loading="goodsLoading"
        :columns="goodsColumns"
        :data="dialogGoodsList"
        :currentPage="goodsPage.pageNum"
        :total="goodsPage.total"
        @handleCurrentChange="GoodsSearch"
      >
        <el-table-column
          width="120"
          label="商品状态"
          slot="shopState"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.state == '00'">未上架</span>
            <span v-else-if="row.state == '03'">在售中</span>
            <span v-else-if="row.state == '05'">已售完</span>
            <span v-else-if="row.state == '10'">已到期</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer">
        <el-button size="small" @click="btnClose">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 门店列表 -->
    <el-dialog
      :title="storeListTitle"
      :visible.sync="storeVisible"
      :close-on-click-modal="false"
      width="1250px"
      @close="btnClose"
    >
      <div class="flex staff-list-form" style="margin-bottom: 20px">
        <div class="flex" style="margin-right: 10px">
          <span style="width: 60px">门店名称</span>
          <el-input
            v-model="storeName"
            size="small"
            clearable
            placeholder="请输入门店名称"
          ></el-input>
        </div>
        <div style="margin-right: 10px">
          <span style="width: 60px">区域/总监</span>
          <el-select
            v-model="storePerson"
            filterable
            multiple
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in storePersonList"
              :key="item.staffId"
              :label="item.staffName"
              :value="item.staffId"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span style="width: 60px">门店标签</span>
          <el-select
            v-model="storeTag"
            filterable
            multiple
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in storeTagList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span style="width: 60px; margin-left: 10px">门店状态</span>
          <el-select
            v-model="storeState"
            filterable
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in storeStateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          style="margin-left: 10px"
          size="small"
          @click="searchStore"
        >
          查询
        </el-button>
      </div>
      <GlobalTable
        ref="GlobalTable"
        :loading="storeLoading"
        :columns="storeColumns"
        :data="dialogStoreList"
        :currentPage="storePage.pageNum"
        :total="storePage.total"
        @handleCurrentChange="StoreSearch"
      >
        <el-table-column label="门店标签" slot="tagList" align="center">
          <template slot-scope="{ row }">
            <span class="tagStyle" v-for="item in row.tagList" :key="item.id">{{
              item.tagName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="门店启用状态" slot="isEnable" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.isEnable ? "启用中" : "已禁用" }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer">
        <el-button size="small" @click="btnClose">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 合同凭证 -->
    <el-dialog
      :close-on-click-modal="false"
      :width="`${800}px`"
      title="合同凭证"
      :visible="imgShow"
      @close="imgClose"
      top="5%"
    >
      <div class="img-box">
        <div class="img-tips-box">
          <div class="img-tips img-tips-choose">合同凭证</div>
        </div>
        <div class="img-show">
          <div class="imgs-num" v-if="DialogImgList.length > 0">
            {{ imgShowIndex + 1 }}/{{ DialogImgList.length }}
          </div>
          <div class="block" v-if="imgShow">
            <viewer :images="DialogImgList">
              <el-carousel trigger="click" height="80vh" @change="getindex">
                <el-carousel-item
                  v-for="(item, index) in DialogImgList"
                  :key="index"
                >
                  <div class="sec_img">
                    <img :src="item" alt="" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </viewer>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ElImgUploadGroup from "../../../components/global/components/elements/upload-group-element.vue";
import _api from "@/utils/request";
import moment from "moment";
export default {
  name: "Table",
  components: { ElImgUploadGroup },
  data() {
    return {
      machineType: [],
      imgShow: false,
      imgShowOnly: true,
      DialogImgList: [],
      imgShowIndex: 0,
      imgIndex: 0,
      cancelResonTxt: "",
      cancelCommandTex: "",
      cancelReceiveShow: false,
      contractId: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 8.64e7;
        },
      },
      timeList: [
        {
          label: "3天",
          value: 3,
        },
        {
          label: "5天",
          value: 5,
        },
        {
          label: "7天",
          value: 7,
        },
        {
          label: "15天",
          value: 15,
        },
        {
          label: "30天",
          value: 30,
        },
      ],
      drawer: false,
      ruleForm: {
        contractName: "",
        contractType: "",
        merchantId: "",
        merchantStaffId: "",
        signatoryPhone: "",
        voucherList: [],
        lifespanTime: "",
      },
      rules: {
        contractName: [
          { required: true, message: "请输入合同名称", trigger: "blur" },
        ],
        contractType: [
          { required: true, message: "请选择合同类型", trigger: "change" },
        ],
        merchantId: [
          { required: true, message: "请选择回收商", trigger: "change" },
        ],
        merchantStaffId: [
          { required: true, message: "请选择代表签署人", trigger: "change" },
        ],
        signatoryPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        lifespanTime: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        voucherList: [
          { required: true, message: "请上传合同签署凭证", trigger: "change" },
        ],
      },
      btnLoading: false,
      // 海报放大
      imgList: [],
      showViewer: false,
      // end
      goodsVisible: false,
      storeVisible: false,
      goodsListTitle: "",
      storeListTitle: "",
      addedGoods: 0,
      addedStore: 0,
      goodsLoading: false,
      storeLoading: false,
      currentRow: {},
      dialogGoodsList: [],
      dialogStoreList: [],
      storePage: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      goodsPage: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      goodsColumns: [
        { label: "商品名称", prop: "productName" },
        { label: "商品分类", prop: "typeName" },
        { slotName: "shopState" },
        { label: "添加时间", prop: "createTime" },
        { label: "商品数量", prop: "stock" },
      ],
      storeColumns: [
        { label: "区域/总监", prop: "inspectorName" },
        { slotName: "tagList" },
        { label: "门店名称", prop: "storeName" },
        { slotName: "isEnable" },
        { label: "添加时间", prop: "createTime" },
      ],
      goodsParams: {
        id: "",
      },
      storeParams: {
        id: "",
      },
      merchantId: "",
      productId: "",
      productList: [],
      goodClassList: [],
      goodsStateList: [
        {
          label: "未上架",
          value: "00",
        },
        {
          label: "在售中",
          value: "03",
        },
        {
          label: "已售完",
          value: "05",
        },
        {
          label: "已过期",
          value: "10",
        },
      ],
      // productName:"",
      goodsClass: "",
      goodsState: "",
      storeName: "",
      storePerson: [],
      storeTag: [],
      storePersonList: [],
      storeTagList: [],
      storeState: null,
      storeStateList: [
        {
          label: "启用中",
          value: true,
        },
        {
          label: "已禁用",
          value: false,
        },
      ],
      loading: false,
      seachDataList: [],
      contractState: "00",
      command: "",
      applyNumber: {},
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      tableColumns: [
        { label: "合同名称", prop: "contractName" },
        { slotName: "contractType" }, //合同类型
        { slotName: "contractDocument" }, //合同凭证
        { slotName: "merchantType" }, //商户类型
        { label: "回收商名称", prop: "enterpriseName" },
        { label: "签署人", prop: "signatoryName" },
        { label: "联系电话", prop: "signatoryPhone" },
        { slotName: "startTime" }, //
        { slotName: "addPeople" }, //添加人
        { label: "添加时间", prop: "createTime" },
        { slotName: "cancellationPeople" }, //作废操作人
        { slotName: "cancellationTime" }, //作废时间
        { slotName: "cancellationCause" }, //作废原因
        { slotName: "operation" },
      ],
      shanpsSelectList: [],
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  created() {
    console.log(Date.now() - 8.64e7,);
    this.handleCurrentChange();
    this.getAllMachineType();
    this.productSelect();
  },
  watch: {
    SeachParams(newVal) {
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    merchantSlect(val) {
      if (val) {
        _api
          .recycleStaffList({ positionType: "01", merchantId: val })
          .then((res) => {
            if (res.code === 1) {
              this.shanpsSelectList = res.data;
            }
          });
      } else {
        this.ruleForm.merchantId = "";
      }
    },
    merchantStaffSelect(e) {
      let arr = this.shanpsSelectList.filter((item) => item.staffId == e);
      this.ruleForm.signatoryPhone = arr[0].staffPhone;
    },
    //回收商名称下拉
    getAllMachineType() {
      _api
        .listSimpleMerchantView({
          isFilterMatrix: "01",
          isNice: "",
          pageNum: 1,
          pageSize: 999999,
        })
        .then((res) => {
          this.machineType = res.data;
        });
    },
    openImgBox(row) {
      console.log(row);
      this.imgShow = true;
      this.DialogImgList = row.voucherList;
      console.log(this.DialogImgList);
    },
    getindex(e) {
      console.log(e);
      this.imgShowIndex = e;
    },
    imgClose() {
      console.log(1);
      this.imgShow = false;
      this.imgList = [];
    },
    seeRemakeImg(row) {
      this.imgList.push(row.img);
      this.showViewer = true;
    },
    handleBtn(row) {
      this.contractId = row.contractId;
      this.cancelReceiveShow = true;
    },
    // 合同作废
    cancelReceiveSure() {
      if (!this.cancelResonTxt) {
        return this.$message.error("请输入作废原因！");
      }
      if (!this.cancelCommandTex) {
        return this.$message.error("请输入动态口令！");
      }
      _api
        .cancelledContract({
          cancelledNote: this.cancelResonTxt,
          command: this.cancelCommandTex,
          contractId: this.contractId,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message({
                message: "操作成功！",
                type: "success",
              });
            this.cancelResonTxt = "";
            this.cancelCommandTex = "";
            this.cancelReceiveShow = false;
            this.handleCurrentChange();
          }
        });
    },
    cancelReceiveClose() {
      this.cancelReceiveShow = false;
      this.cancelResonTxt = "";
      this.cancelCommandTex = "";
    },
    // 上传图片后，将图片信息保存到对应参数中
    handleUploadGroupChange(list) {
      this.ruleForm.voucherList = list;
      console.log(list, this.ruleForm.voucherList);
    },
    siginClose(done) {
      this.drawer = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const awObject = JSON.parse(JSON.stringify(this.ruleForm));
          console.log(this.ruleForm, awObject);
          awObject.startTime = awObject.lifespanTime[0];
          awObject.endTime = awObject.lifespanTime[1];
          if(awObject.endTime<=Date.now() - 8.64e7){
            return this.$message.error("结束日期请选择今天之后！");
          }
          _api.addContract(awObject).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "操作成功！",
                type: "success",
              });
              this.$refs[formName].resetFields();
              this.drawer = false;
              this.handleCurrentChange();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.drawer = false;
    },
    //添加证书
    addCertificate(type, data) {
     
      this.CertificateType = type;
      if (type == "add") {
        this.ruleForm = {
          contractName: "",
          contractType: "",
          merchantId: "",
          merchantStaffId: "",
          signatoryPhone: "",
          voucherList: [],
          lifespanTime: "",
        };
      } else {
        this.ruleForm = {
          lifespanTime:"",
          ...JSON.parse(JSON.stringify(data))};
        this.ruleForm.merchantId = this.ruleForm.enterpriseId;
        this.ruleForm.voucherList = [];
        _api
          .recycleStaffList({ positionType: "01", merchantId: this.ruleForm.enterpriseId })
          .then((res) => {
            if (res.code === 1) {
              this.shanpsSelectList = res.data;
              this.ruleForm.merchantStaffId = this.ruleForm.signatoryId;
            }
          });
          console.log(this.ruleForm);
      }
      this.drawer = true;
    },
    // 查看示例图大图
    seeSampleImage(posterImg) {
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(posterImg);
    },
    searchGoods() {
      this.goodsPage.pageNum = 1;
      this.searchSettingGoods();
    },
    GoodsSearch(val) {
      if (val) {
        this.goodsPage.pageNum = val;
      }
      this.searchSettingGoods();
    },
    // 商品查询
    searchSettingGoods() {
      this.goodsLoading = true;
      const params = {
        activityId: this.currentRow.activityId,
        productId: this.productId,
        pageNum: this.goodsPage.pageNum,
        pageSize: this.goodsPage.pageSize,
        // productName: this.productName,
        state: this.goodsState,
        merchantId: this.goodsClass,
      };
      _api.getRenewProductsList(params).then((res) => {
        if (res.code === 1) {
          this.dialogGoodsList = res.data.records;
          (this.addedGoods = res.data.total),
            (this.goodsListTitle = `已添加商品(${this.addedGoods})`);
          this.goodsPage.pageNum = res.data.current;
          this.goodsPage.total = res.data.total;
          this.goodsLoading = false;
        }
      });
    },
    searchStore() {
      this.storePage.pageNum = 1;
      this.searchSettingStore();
    },
    StoreSearch(val) {
      if (val) {
        this.storePage.pageNum = val;
      }
      this.searchSettingStore();
    },
    // 门店查询
    searchSettingStore() {
      this.storeLoading = true;
      const params = {
        activityId: this.currentRow.activityId,
        inspectorIds: this.storePerson,
        isEnable: this.storeState,
        storeName: this.storeName,
        tagIds: this.storeTag,
        pageNum: this.storePage.pageNum,
        pageSize: this.storePage.pageSize,
      };
      _api.getRenewStoresList(params).then((res) => {
        if (res.code == 1) {
          this.dialogStoreList = res.data.records;
          (this.addedStore = res.data.total),
            (this.storeListTitle = `已添加门店(${this.addedStore})`);
          this.storePage.pageNum = res.data.current;
          this.storePage.total = res.data.total;
          this.storeLoading = false;
        }
      });
    },
    // 列表关闭
    btnClose() {
      this.productId = "";
      this.goodsClass = "";
      this.goodsState = "";
      this.storeName = "";
      this.storePerson = [];
      this.storeTag = [];
      this.storeState = null;
      this.addedStore = 0;
      this.addedGoods = 0;
      this.storePage = {
        pageNum: 1,
        total: 0,
      };
      this.goodsPage = {
        pageNum: 1,
        total: 0,
      };
      this.goodsVisible = false;
      this.storeVisible = false;
    },
    goodsDialog(row) {
      this.currentRow = row;
      this.goodsVisible = true;
      this.productSelect(row.activityId, row.companyId);
      this.getGoodsClassSelectList();
      this.searchSettingGoods();
    },
    //合同类型下拉
    productSelect() {
      _api.gettypeList().then((res) => {
        this.productList = res.data;
      });
    },
    // 商品所属分类下拉
    getGoodsClassSelectList() {
      _api.activityTypeSelect({ companyId: this.companyId }).then((res) => {
        if (res.code === 1) {
          this.goodClassList = res.data;
        }
      });
    },
    storeDialog(row) {
      this.currentRow = row;
      this.storeVisible = true;
      this.getPersonSelectList();
      this.getStoreTagsSelectList();
      this.searchSettingStore();
    },
    // 门店区域/总监下拉
    getPersonSelectList() {
      _api
        .inspectorAreaSelect({ companyId: this.currentRow.companyId })
        .then((res) => {
          if (res.code === 1) {
            this.storePersonList = res.data;
          }
        });
    },
    // 门店标签下拉
    getStoreTagsSelectList() {
      _api
        .getStoreTagSelect({ companyId: this.currentRow.companyId })
        .then((res) => {
          if (res.code === 1) {
            this.storeTagList = res.data;
          }
        });
    },
    auditTrunon(row, type) {
      this.currentRow = row;
      console.log(12345, row, type);
      if (type == "edit") {
        this.drawerDialog = true;
        this.drawerInitData = {
          activityName: this.currentRow.activityName,
          userImg: this.currentRow.activityPoster,
          entryTime: this.currentRow.createTime,
          endTime: this.currentRow.activityStart,
          userSex: this.currentRow.userSex,
        };
      }
      this.auditShow = true;
    },
    // 跳转至换新订单
    goRenewOrder(row) {
      console.log(234, row);
      let routeData = this.$router.resolve({
        path: "/newMarkup/renewOrder",
        query: {
          type: "edit",
          activityId: row.activityId,
          companyId: row.companyId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 表格切换页面
    handleCurrentChange(val) {
      console.log("列表查询");
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        contractState: this.contractState,
        signatoryName: this.SeachParams.signatoryName,
        signatoryPhone: this.SeachParams.signatoryPhone,
        contractName: this.SeachParams.contractName,
        contractType: this.SeachParams.contractType,
        signatoryId: this.SeachParams.signatoryId,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };

      this.loading = true;
      _api.getcontractList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.list.records;
          this.applyNumber.startNum = res.data.startNum;
          this.applyNumber.unStartNum = res.data.unStartNum;
          this.applyNumber.overdueNum = res.data.overdueNum;
          this.applyNumber.cancelledNum = res.data.cancelledNum;
          this.page.total = res.data.list.total;
          this.page.pageNum = res.data.list.current;
          this.loading = false;
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      });
    },
    btnClick(type) {
      this.contractState = type;
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.center_book {
  padding: 5px 20px;
}

.sec_img {
  width: 20vw;
  height: 80vh;
  margin: auto;

  img {
    width: 20vw;
    height: 80vh;
    object-fit: contain;
  }
}
// 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }

  // 列表
}

.GlobalTable {
  .wrapStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .staff-list-form {
    .el-input {
      width: 170px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .button_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .repair-conent {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .faultBtn {
    //width: 432px;
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      padding: 0 20px;
      // width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981ff;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      padding: 0 20px;
      // width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .remarks-tip {
    color: #0981ff;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}

.call_accounts_info {
  margin: 10px 0;
}

.call_accounts_check {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 14px;
}

.call_accounts_result {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.el_from {
  margin-left: 30px;

  .form_flex_cont {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }

  .drawerWith {
    width: 500px;
  }

  .timeStyle {
    margin-top: 15px;
  }
}

.tagStyle {
  border: 1px solid #0981ff;
  border-radius: 10px;
  padding: 3px 10px;
  color: #0981ff;
  margin-right: 10px;
}
.text-down {
  color: #0981ff;
  cursor: pointer;
  text-decoration: underline;
}
</style>
